import React, { Component } from 'react';
// import { Link } from 'gatsby';
import PageWrapper from '../styles/index.css.js';
import HomeHeader from '../components/Home/HomeHeader/HomeHeader'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <PageWrapper>
        <HomeHeader 
          headerImage = "3"
          rotate={false}
          button={false}
        />
        <div className="about-body">
          <div className="about-box">
            <img src="https://webcdn.hirezstudios.com/rogue-company/static/first-watch/Screenshot2.jpg" alt="First Watch" />
            <div className="text-box">
              <div className="underline-header">About</div>
              <h2>We'll Take the First Watch</h2>
              <p>First Watch Games is a new game development studio under the Hi-Rez Studios banner, consisting of senior developers from Hi-Rez Studios, Blizzard, Daybreak, Electronic Arts, Vicarious Vision, and more. This team of veteran developers have banded together to make the next best multiplayer shooter, Rogue Company. First Watch Games and Hi-Rez Studios believe that gamers should be able to play with their friends, regardless of platform. Along with making a great shooter, the studio is dedicated to implementing crossplay to bring the community together.</p>
              <a className="button" target="_blank" href="https://www.roguecompany.com/" rel="noopener noreferrer">Visit Rogue Company</a>
            </div>
          </div>
        </div>
        <div className="cta-info">
          <div className="underline-header">Join Us</div>
          <p>Learn more about Careers at First Watch Games</p>
          <a className="button" target="_blank" href="https://www.hirezstudios.com/careers#first-watch-games" rel="noopener noreferrer">Explore Careers</a>
        </div>
      </PageWrapper>
    );
  };
}

export default Home;
