import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  background-color: #292929;
	background-size: cover;
	color: #FFF;
  height: calc(100vw * (100 / 256));
  max-height: 100rem;
  min-height: 30rem;
  position: relative;
  width: 100vw;
  transition: background-image 0.2s ease-in;
  .headline-button-container {
  	align-items: center;
  	color: #FFF;
  	display: flex;
  	flex-direction: column;
  	height: 100%;
  	justify-content: center;
  	position: relative;
  	text-align: center;
  	text-transform: uppercase;
  	z-index: 1;
		h1 {
		  align-items: center;
		  display: flex;
		  flex-direction: column;
		  font-family: 'Open Sans', sans-serif;
		  font-size: 5.5rem;
		  font-weight: bold;
		  margin: 0 auto;
		  position: relative;
		  text-align: center;
		  text-transform: uppercase;
		  &:after {
			  background-color: #FFB400;
			  content: '';
			  height: 3px;
			  margin-top: 2.4rem;
			  width: 10rem;
		  }
		}
		.button {
			background-color: #FFB400;
			border: none;
			color: #4A4A4A;
			display: inline-block;
			font-size: 2.4rem;
			font-weight: bold;
			margin-top: 3.6rem;
			padding: 2.5rem 5rem;
			text-decoration: none;
		}
  }
  .carousel {
		align-items: center;
		border-bottom: 2px solid #FFB400;
  	display: flex;
  	height: 100%;
  	justify-content: center;
  	position: relative;
  	width: 100%;
  }
  .carousel-navigation {
  	bottom: 5%;
  	display: flex;
  	margin: 0 auto;
  	justify-content: center;
  	position: absolute;
  	width: 100%;
  }
  .carousel-nav-link {
  	background-color: #4A4A4A;
  	cursor: pointer;
  	height: 1rem;
  	margin: 0 1.2rem;
  	width: 11rem;
  	&.active {
  		background-color: #E5E5E5;
  	}
  	@media only screen and (max-width: 600px) {
	  	width: 6rem;
  	}
  }
  .slide-content {
  	display: block;
  	margin: 0 auto;
  	opacity: 0;
  	position: absolute;
  	transition: opacity 0.2s ease-in;
  	z-index: 1;
  	&.active {
  		opacity: 1;
  	}
  	&.logo img {
  		max-width: 67rem;
  		min-width: 20rem;
  		width: calc(100vw * (70 / 256));
  	}
  	&.text {
  		bottom: 15%;
  		p {
  			font-family: "Open Sans", sans-serif;
		  	font-size: 6rem;
		  	font-weight: bold;
		  	text-align: center;
		  	text-transform: uppercase;
		  	@media (max-width: 600px) {
		  		font-size: 3rem;
		  	}
	  	}
	  }
  }
`;