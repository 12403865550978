import styled from 'styled-components'

const PageWrapper = styled.div`
  position: relative;
  text-align: center;
  .cta-info {
    color: #fff;
    margin: 10rem auto;
    text-align: center;
    @media (max-width: 1100px) {
      margin: 0 auto;
    }
  }
  p {
    font-size: 1.8rem;
    margin-top: 3rem;
  }
  .button {
    background-color: #ffb400;
    color: #4a4a4a;
    cursor: pointer;
    display: inline-block;
    filter: brightness(1);
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    margin-top: 5rem;
    padding: 3rem 5rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: filter 0.2s ease-out;
    &:hover {
      filter: brightness(1.2);
    }
  }
  h1 {
    @media (max-width: 600px) {
      font-size: 3rem;
    }
  }
  .button {
    background-color: transparent;
    border: 2px solid #ffb400;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 2.4rem;
    font-weight: bold;
    margin: 4rem auto;
    padding: 2.5rem 5rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.15s ease-in;
    &:hover {
      background-color: #ffb400;
    }
    @media (max-width: 800px) {
      padding: 1rem;
    }
  }
  .about-body {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-family: 'Open Sans', sans-serif;
    margin: 10rem auto;
    max-width: 160rem;
    overflow: hidden;
    position: relative;
    @media (max-width: 1100px) {
      margin: 0 auto;
    }
  }
  .floating-image {
    left: 18%;
    max-width: 60rem;
    min-width: 20rem;
    position: absolute;
    top: -5%;
    width: 30vw;
    @media (max-width: 600px) {
      left: 50%;
      top: -9%;
      transform: translateX(-50%);
    }
    &.bottom {
      max-width: 58rem;
      min-width: 20rem;
      position: relative;
      width: auto;
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
  .about-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 1100px) {
      align-items: center;
      display: flex;
      flex-direction: column-reverse;
      margin: 5rem auto 5rem;
    }
    img {
      max-width: 46rem;
      min-width: 20rem;
      width: 50vw;
    }
  }
  .underline-header {
    align-items: center;
    text-transform: initial;
    margin: 0;
    &:after {
      width: 10rem;
    }
  }
  .text-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 64rem;
    min-width: 25rem;
    position: relative;
    @media (max-width: 700px) {
      padding: 0 2rem 0 1rem;
    }
    img {
      margin-left: -20vw;
      margin-top: 28%;
      width: 35vw;
    }
  }
  .text-box {
    p {
      font-size: 1.8rem;
      text-align: center;
      line-height: 1.7;
      margin-top: 4rem;
      padding: 0 30px;
    }
  }
  h2 {
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-size: 5rem;
    font-weight: bold;
    margin-top: 5rem;
  }
  .text-box .button {
    align-self: center;
  }
  .core-pillars {
    margin-top: 10rem;
    text-align: center;
    .underline-header {
      align-items: center;
      text-decoration: uppercase;
    }
    .pillar-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 4rem;
      max-width: 160rem;
      width: 100vw;
    }
  }
`

export default PageWrapper
